import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import TwitterSVG from '../assets/TwitterSvg';

const screenWidth = Dimensions.get('window').width;
const isPhone = screenWidth < 500;

const SocialMedia = () => {
  const navigation = useNavigation();

  const openLink = (url) => {
    Linking.openURL(url).catch((err) => console.error("Couldn't load page", err));
  };

 

  return (
    <View style={styles.headerContainer}>
      <View style={[styles.iconContainer, isPhone && styles.iconContainerPhone]}>
        <TouchableOpacity onPress={() => openLink('https://www.instagram.com/letspepapp/')}>
          <FontAwesome name="instagram" size={24} color="#F0766A" style={styles.icon} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openLink('https://twitter.com/letspepapp')}>
           <TwitterSVG width={24} height={24} color="#F0766A" style={styles.icon} /> 
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openLink('https://tr.linkedin.com/company/pepapp')}>
          <FontAwesome name="linkedin" size={24} color="#F0766A" style={styles.icon} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom:50,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent:'center',
    alignItems:'center'
  },

  icon: {
    margin:20
  },
});

export default SocialMedia;
