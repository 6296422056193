import AsyncStorage from '@react-native-async-storage/async-storage';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getInitialLanguage } from './Language';

const TEXTS_STORAGE_KEY = 'app_texts';

export const fetchAndCacheTexts = async (language) => {
  try {
    // Use the provided language or get the initial language
    const currentLanguage = language || getInitialLanguage();
    console.log("Current language", currentLanguage);

    // Define a cache key that includes the language
    const languageSpecificKey = `${TEXTS_STORAGE_KEY}_${currentLanguage}`;

    // Fetch texts from Firebase for the current language
    const pages = ['Footer', 'Home', 'TermsOfUse', 'PrivacyPolicy']; // Add other pages as needed
    const texts = {};

    for (const page of pages) {
      // Force "en" for "TermsOfUse" and "PrivacyPolicy"
      const pageLanguage = ['TermsOfUse', 'PrivacyPolicy'].includes(page) ? 'en' : currentLanguage;
      const docRef = doc(db, `/web/texts/language/${pageLanguage}/pages/${page}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        texts[page] = docSnap.data();
      } else {
        console.error(`No data found for page: ${page}`);
      }
    }

    // Cache the fetched texts in AsyncStorage
    await AsyncStorage.setItem(languageSpecificKey, JSON.stringify(texts));

    return texts; // Return the freshly fetched texts
  } catch (error) {
    console.error('Error fetching or caching texts:', error);
    return null; // Return null in case of an error
  }
};

export const getCachedTexts = async (language = null) => {
  try {
    const currentLanguage = language || getInitialLanguage();
    const languageSpecificKey = `${TEXTS_STORAGE_KEY}_${currentLanguage}`;
    const cachedTexts = await AsyncStorage.getItem(languageSpecificKey);
    return cachedTexts ? JSON.parse(cachedTexts) : null;
  } catch (error) {
    console.error('Error fetching cached texts:', error);
    return null;
  }
};