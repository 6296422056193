import React , {useEffect} from 'react';
import { StyleSheet, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Header from './src/views/Header'; 
import HomePage from './src/pages/HomePage';
import PrivacyPolicy from './src/pages/PrivacyPolicy'; 
import TermsOfUse from './src/pages/TermsOfUse';

const Stack = createStackNavigator();

export default function App() {
  useEffect(() => {
    document.title = "PepAI"; 
  }, []);

  const linking = {
    prefixes: ['https://pepai-99e26.web.app', 'pepai://'],
    config: {
      screens: {
        Home: '',
        PrivacyPolicy: 'privacy-policy',
        TermsOfUse: 'terms-of-use',
      },
    },
  };


  return (
    <NavigationContainer linking={linking} style={{backgroundColor:'#141218'}}>
      <View style={styles.container}>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Home" component={HomePage} />
          <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy}  />
          <Stack.Screen name="TermsOfUse" component={TermsOfUse} />
        </Stack.Navigator>
      </View>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#141218',
    padding:1
  },
});
