import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

const Footer = ({navigation, termsofuse , privacypolicy }) => {
  
  return (
    <View style={styles.footerContainer}>
      <TouchableOpacity onPress={() => navigation.navigate('TermsOfUse')}>
        <Text style={styles.linkText}>{termsofuse}</Text>
      </TouchableOpacity>
      <Text style={styles.separator}>|</Text>
      <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
        <Text style={styles.linkText}>{privacypolicy}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  footerContainer: {
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
    borderTopWidth: 1,
    borderColor: '#444',
  },
  linkText: {
    color: '#fff',
    marginRight:50,
    marginLeft:50,
    fontSize: 14,
    marginHorizontal: 10,
    textDecorationLine: 'underline',
  },
  separator: {
    color: '#fff',
    fontSize: 14,
  },
});

export default Footer;
