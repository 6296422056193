import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, StyleSheet, ActivityIndicator } from 'react-native';
import Background from '../views/Bg';
import Footer from '../views/Footer';
import { getCachedTexts } from '../utils/FetchTexts';
import { TouchableOpacity } from 'react-native';
const PrivacyPolicy = ({ navigation }) => {
  const [texts, setTexts] = useState(null); // State to hold fetched texts
  const [loading, setLoading] = useState(true); // State to handle loading

  useEffect(() => {
    const initializeTexts = async () => {
      try {
        console.log("Initializing texts...");
        const fetchedTexts = await getCachedTexts();
        console.log("Fetched texts:", fetchedTexts);

        if (fetchedTexts) {
          setTexts(fetchedTexts); // Update state with fetched data
        }
      } catch (error) {
        console.error("Error fetching texts:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    initializeTexts();
  }, []); // Run only once when the component mounts

  useEffect(() => {
    document.title = "PepAI"; // Set the document title
  }, []);

  if (loading) {
    // Show a loading spinner while fetching data
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#fff" />
      </View>
    );
  }

  if (!texts || !texts.PrivacyPolicy) {
    // Handle case where texts are not available
    return (
      <View style={styles.loadingContainer}>
        <Text style={styles.errorText}>Privacy Policy could not be loaded.</Text>
      </View>
    );
  }

  const { title, sections } = texts.PrivacyPolicy;

  return (
    <View style={styles.container}>
      <Background />
      <ScrollView contentContainerStyle={styles.content}>
        <View style={styles.header}>
          {/* Back button for web */}
          <TouchableOpacity onPress={() => navigation.navigate('Home')} style={styles.backButton}>
            <Text style={styles.backButtonText}>←</Text>
          </TouchableOpacity>
          <Text style={styles.title}>{title}</Text>
        </View>
        {sections.map((section, index) => (
          <View key={index}>
            <Text style={styles.sectionTitle}>{section.sectionTitle}</Text>
            <Text style={styles.paragraph}>{section.content}</Text>
          </View>
          
        ))}
        <Footer 
        navigation={navigation} 
        privacypolicy={texts.Footer?.privacypolicy} 
        termsofuse={texts.Footer?.termsofuse} 
        style={styles.footer} 
      />
      </ScrollView>
      
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141218',
  },
  errorText: {
    color: 'red',
    fontSize: 18,
    textAlign: 'center',
  },
  container: {
    flex: 1, // Ensure container takes full height
    backgroundColor: '#141218',
  },
  content: {
    flexGrow: 1, // Ensure ScrollView content expands fully
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  header: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Centers title horizontally
    marginBottom: 15,
  },
  backButton: {
    position: 'absolute',
    left: 0, // Aligns the back button to the left
    padding: 10,
  },
  backButtonText: {
    fontSize: 18,
    color: '#fff',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#ffffff',
    textAlign: 'center', // Ensures the title text is centered
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
    marginVertical: 10,
  },
  paragraph: {
    fontSize: 16,
    color: '#cccccc',
    lineHeight: 24,
    marginBottom: 10,
  },
  footer: {
    marginTop: 0, // Ensure no gap between content and footer
  },
});

export default PrivacyPolicy;