import { I18nManager } from "react-native";

export const getInitialLanguage = () => {
  let language = null;

  try {
    language = localStorage.getItem("appLanguage");
  } catch (e) {
    console.error("Error reading language from local storage", e);
  }

  if (!language) {
    const deviceLanguage =
      I18nManager.isRTL ? "ar" : navigator.language || "en";
    language = deviceLanguage.substring(0, 2); 
    setLanguage(language);
  }
  return language;
};

export const setLanguage = (language) => {
  try {
    localStorage.setItem("appLanguage", language);
  } catch (e) {
    console.error("Error setting language in local storage", e);
  }
};