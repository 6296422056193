import React from 'react';
import { StyleSheet, View, Image } from 'react-native';

const Background = () => {
  return (
    <View style={styles.container}>
      {/* Top-left circle image */}
      <Image
        source={require('../assets/circleTop.png')}
        style={styles.topCircle}
      />

      {/* Bottom-right circle image */}
      <Image
        source={require('../assets/circleBottom.png')}
        style={styles.bottomCircle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute', // Ensures it covers the whole screen
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -1, // Place behind other components
    backgroundColor: '#141218', // Fallback background color
  },
  topCircle: {
    position: 'absolute',
    top: -100, // Offset to make it partially off-screen
    left: -100,
    width: 600,
    height: 600,
    opacity: 0.3, // Static opacity for a subtle background effect
  },
  bottomCircle: {
    position: 'absolute',
    bottom: -100, // Offset to make it partially off-screen
    right: -100,
    width: 600,
    height: 600,
    opacity: 0.3,
  },
});

export default Background;
