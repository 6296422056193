import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Animated, Dimensions } from 'react-native';
import Footer from '../views/Footer';
import Background from '../views/Bg';
import PepAiLogo from '../assets/PepAiLogo.png'
import AppleSVG from '../assets/AppleSvg'
import AppleSVGEn from '../assets/AppleSvgEn';
import PoweredBy from '../assets/PoweredByPepapp.png'
import SocialMedia from '../views/SocialMedia';
import { fetchAndCacheTexts } from '../utils/FetchTexts';
import { setLanguage , getInitialLanguage } from '../utils/Language';
import LanguageSelector from '../views/LanguageSelecter';

const screenWidth = Dimensions.get('window').width;
const isPhone = screenWidth < 500;
const scaleFactor = isPhone ? screenWidth / 400 : 1;
const scaleFactor2 = isPhone ? (screenWidth / 375) * 0.45 : 1;
const scaleFactor3 = isPhone ? (screenWidth / 375) * 0.8 : 1;
const scaleFactor4 = isPhone ? (screenWidth / 375) * 2: 1;
const scaleFactor5 = isPhone ? (screenWidth / 375) * 0.9: 1;
const poweredByScale = isPhone ? -35 : 70;
const mainContainerScale = isPhone ? 0 : 100;

const heightFactor = isPhone ? (screenWidth / 375) * 0.48 * 650 : 700;
const widthFactor2 = isPhone ? '100%' : '80%';

const HomePage = ({ navigation }) => {
  const [loading, setLoading] = useState(true);
  const [texts, setTexts] = useState({});
  const [language, setAppLanguage] = useState('');

  const scrollY = useRef(new Animated.Value(0)).current;

  const fadeAnimLogo = useRef(new Animated.Value(0)).current;
  const fadeAnimText = useRef(new Animated.Value(0)).current;
  const fadeAnimDescription = useRef(new Animated.Value(0)).current;
  const fadeAnimButton = useRef(new Animated.Value(0)).current;

  const introOpacity = useRef(new Animated.Value(0)).current;
  const descriptionOpacity = useRef(new Animated.Value(0)).current;
  const appStoreOpacity = useRef(new Animated.Value(0)).current;
  const pepaiOpacity = useRef(new Animated.Value(0)).current;

  const startAnimations = () => {
    // Reset animation values to 0
    fadeAnimLogo.setValue(0);
    fadeAnimText.setValue(0);
    fadeAnimDescription.setValue(0);
    fadeAnimButton.setValue(0);
    introOpacity.setValue(0);
    descriptionOpacity.setValue(0);
    appStoreOpacity.setValue(0);
    pepaiOpacity.setValue(0);

    // Start the animations after a delay
    setTimeout(() => {
      Animated.sequence([
        Animated.timing(introOpacity, {
          toValue: 1,
          duration: 750,
          useNativeDriver: true,
        }),
        Animated.timing(descriptionOpacity, {
          toValue: 1,
          duration: 750,
          useNativeDriver: true,
        }),
        Animated.timing(appStoreOpacity, {
          toValue: 1,
          duration: 750,
          useNativeDriver: true,
        }),
        Animated.timing(pepaiOpacity, {
          toValue: 1,
          duration: 750,
          useNativeDriver: true,
        }),
      ]).start();

      Animated.sequence([
        Animated.timing(fadeAnimLogo, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnimText, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnimDescription, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnimButton, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
      ]).start();
    }, 1000); // Delay of 2 seconds
  };

  useEffect(() => {
    const initializeLanguage = async () => {
      const initialLanguage = getInitialLanguage(); // Retrieve saved language or default
      setAppLanguage(initialLanguage);
    };

    initializeLanguage();
  }, []);

  useEffect(() => {
    const fetchTexts = async () => {
      setLoading(true); // Set loading to true
      const fetchedTexts = await fetchAndCacheTexts(language);
      if (fetchedTexts) {
        setTexts(fetchedTexts); // Update texts state
        startAnimations(); // Restart animations on language change
      }
      setLoading(false); // Set loading to false
    };

    if (language) {
      fetchTexts();
    }
  }, [language]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage); // Save new language to storage
    setAppLanguage(newLanguage); // Update state
  };

  if (loading) {
    return (
      <View
        style={[
          styles.container,
          { justifyContent: 'center', alignItems: 'center', backgroundColor: '#141218', flex: 1 },
        ]}
      >
        <Background />
      </View>
    );
  }

  const phonePosition = scrollY.interpolate({
    inputRange: [0, 300],
    outputRange: [0, -300 * scaleFactor5],
    extrapolate: 'clamp',
  });

  const pepaiTextPosition = scrollY.interpolate({
    inputRange: [0, 500],
    outputRange: [0, 245 * scaleFactor4],
    extrapolate: 'clamp',
  });

  const logoScale = scrollY.interpolate({
    inputRange: [0, 400],
    outputRange: [1, 4 * scaleFactor2],
    extrapolate: 'clamp',
  });
  const logoRotation = scrollY.interpolate({
    inputRange: [0, 1500],
    outputRange: ['0deg', '360deg'],
    extrapolate: 'clamp',
  });
  const pepaiTextOpacity = scrollY.interpolate({
    inputRange: [0, 500],
    outputRange: [1, 0.3],
    extrapolate: 'clamp',
  });

  return (
    <View style={styles.mainContainer}>
      <Background />
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
      >
        <LanguageSelector language={language} changeLanguage={changeLanguage} />

        <Animated.View style={{ opacity: fadeAnimLogo }}>
          {isPhone ? (
            <Image source={PepAiLogo} style={styles.logo} />
          ) : (
            <Animated.Image
              source={PepAiLogo}
              style={[
                styles.logo,
                { opacity: pepaiTextOpacity },
                {
                  transform: [
                    { scale: logoScale },
                    { translateY: pepaiTextPosition },
                    { rotate: logoRotation },
                  ],
                },
              ]}
            />
          )}
        </Animated.View>

        {/* Intro Text */}
        <Animated.View style={{ opacity: fadeAnimText }}>
          <Text style={styles.introText}>{texts.Home.title}</Text>
        </Animated.View>

        {/* Description */}
        <Animated.View style={{ opacity: fadeAnimDescription }}>
          <Text style={styles.description}>{texts.Home.description}</Text>
        </Animated.View>

        <Animated.View style={{ opacity: fadeAnimButton }}>
          <TouchableOpacity
            onPress={() => {
              const url = 'https://apps.apple.com/tr/app/pepai-your-daily-companion/id6670434483';
              if (Platform.OS === 'web') {
                window.open(url, '_blank');
              } else {
                Linking.openURL(url).catch((err) =>
                  console.error("Couldn't load page", err)
                );
              }
            }}
            style={{width:200 , alignSelf:'center'}}
          >
            <View style={styles.appStoreImage}>
              {language === 'tr' ? <AppleSVG /> : <AppleSVGEn />}
            </View>
          </TouchableOpacity>
          <SocialMedia />
        </Animated.View>

        {!isPhone && (
          <>
            <Animated.Image
              source={require('../assets/Animation.gif')}
              style={[styles.gif, { opacity: pepaiTextOpacity }]}
              resizeMode="contain"
            />

          <Animated.Image
            source={
              language === 'en'
                ? require('../assets/ensonen.png') // Use ensonen.png for English
                : require('../assets/enson.png')  // Use enson.png for other languages
            }
            style={[styles.phone, { transform: [{ translateY: phonePosition }] }]}
            resizeMode="contain"
          />
          </>
        )}

        <View style={styles.bottomContainer}></View>
        <Image source={PoweredBy} style={styles.poweredBy} />

        <Footer
          navigation={navigation}
          privacypolicy={texts.Footer.privacypolicy}
          termsofuse={texts.Footer.termsofuse}
          style={styles.footer}
        />
      </Animated.ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingHorizontal: 20 * scaleFactor,
    paddingTop:20,
    backgroundColor:'#141218',
  },
  bgContainer: {
    position: 'absolute',
    top: 106 * scaleFactor,
    left: 100 * scaleFactor,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: '39%',  
    height: '59%', // Adjust this value to control height
    zIndex: -1,
    borderRadius:30,
  },
  languageSelector: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf:'center',
    padding: 10,
    width:200,
  },
  languageButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  languageButtonText: {
    marginLeft: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },
  introText: {
    fontSize: 50 * scaleFactor,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
  },
  bottomContainer: {
    top: -200,
  },
  description: {
    fontSize: 20 * scaleFactor,
    color: '#5E6272',
    textAlign: 'center',
    alignSelf: 'center', 
    width: 350 * scaleFactor,
  },
  avatarDescription: {
    fontSize: 15 * scaleFactor,
    color: '#5E6272',
    textAlign: 'center',
    alignSelf: 'center', 
  },
  assistantDesc: {
    fontSize: 30 * scaleFactor3,
    color: '#fff',
    textAlign: 'center',
    marginVertical: 20 * scaleFactor,
    fontWeight: 'bold',
  },
  appStoreImage: {
    alignSelf: 'center',
    marginBottom: 20 * scaleFactor,
    borderRadius: 20 * scaleFactor,
    marginTop: 20 * scaleFactor,
  },
  phone: {
    width: widthFactor2 ,
    height: heightFactor,
    alignSelf: 'center',
    marginBottom: 20,
    borderRadius: 20,
    top:400,
  },

  pepaiTextImage: {
    width: '80%', 
    height: heightFactor,
    resizeMode: 'contain', // Ensures the image scales while preserving aspect ratio
    alignSelf: 'center',   // Centers the image horizontally within its parent
  },
  gif: {
    width: 100 * scaleFactor,
    height: 100 * scaleFactor,
    alignSelf: 'center',
    },
  logo: {
    width: 200 * scaleFactor, 
    height: 200 * scaleFactor,
    alignSelf: 'center', 
    marginBottom: 20 ,   
    marginTop: mainContainerScale,  
  },
  poweredBy: {
    paddingTop:mainContainerScale,
    width:'100%',
    height: 50,
    width:100,
    alignSelf: 'center',
    resizeMode:'contain',
    marginBottom: 20,
    marginTop:poweredByScale,
  },
  footer: {
  }

});

export default HomePage;