import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, Linking, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import PepAiLogo from '../assets/PepAiLogo.png';
import { FontAwesome } from '@expo/vector-icons';

const screenWidth = Dimensions.get('window').width;
const isPhone = screenWidth < 500;

const Header = () => {
  const navigation = useNavigation();
  const [isHovered, setIsHovered] = useState(false);

  const openLink = (url) => {
    Linking.openURL(url).catch((err) => console.error("Couldn't load page", err));
  };

  const goToHome = () => {
    navigation.navigate('Home');
  };

  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity
        style={[styles.leftContainer, isPhone && styles.leftContainerPhone]}
        onPress={goToHome}
        onMouseEnter={() => setIsHovered(true)}   // Set hover to true on mouse enter
        onMouseLeave={() => setIsHovered(false)}  // Set hover to false on mouse leave
      >
        <Image source={PepAiLogo} style={styles.logo} />
        <Text style={[styles.headerText, isHovered && styles.headerTextHovered]}>PepAI</Text>
      </TouchableOpacity>

      <View style={[styles.iconContainer, isPhone && styles.iconContainerPhone]}>
        <TouchableOpacity onPress={() => openLink('https://www.instagram.com/letspepapp/')}>
          <FontAwesome name="instagram" size={24} color="#F0766A" style={styles.icon} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openLink('https://twitter.com/letspepapp')}>
          <FontAwesome name="twitter" size={24} color="#F0766A" style={styles.icon} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openLink('https://tr.linkedin.com/company/pepapp')}>
          <FontAwesome name="linkedin" size={24} color="#F0766A" style={styles.icon} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#141218',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#F0766A',
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginStart: 80,
  },
  leftContainerPhone: {
    marginStart: 20,
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    marginRight: 10,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  headerTextHovered: {
    color: '#F0766A',  // Color on hover
  },
  iconContainer: {
    flexDirection: 'row',
    marginEnd: 80,
  },
  iconContainerPhone: {
    marginEnd: 40,
  },
  icon: {
    marginLeft: 15,
  },
});

export default Header;
