import React, { useState, useRef } from 'react';
import { View, TouchableOpacity, StyleSheet, Animated } from 'react-native';
import Flag from 'react-native-flags';
import { MaterialIcons } from '@expo/vector-icons';

const LanguageSelector = ({ language, changeLanguage }) => {
  const [selectedFlag, setSelectedFlag] = useState(language); // Track the selected language
  const [expanded, setExpanded] = useState(false); // Track whether flags are expanded

  const arrowRotation = useRef(new Animated.Value(0)).current; // Track arrow rotation

  const flags = [
    { code: 'GB', lang: 'en' },
    { code: 'TR', lang: 'tr' },
    // { code: 'ES', lang: 'es' },
  ];

  const handlePress = (lang) => {
    if (!expanded) {
      setExpanded(true); // Expand the flags if clicked
      rotateArrow(1); // Rotate arrow downward
    } else if (lang !== selectedFlag) {
      setSelectedFlag(lang);
      changeLanguage(lang);
      setExpanded(false); // Collapse after selecting a flag
      rotateArrow(0); // Rotate arrow back to upward
    } else {
      setExpanded(false); // Collapse if clicking the already selected flag
      rotateArrow(0); // Rotate arrow back to upward
    }
  };

  const rotateArrow = (toValue) => {
    Animated.timing(arrowRotation, {
      toValue,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const renderFlag = (code, lang, isSelected, isVisible) => (
    <TouchableOpacity
      key={lang}
      onPress={() => handlePress(lang)}
      style={[
        styles.languageButton,
        isVisible ? styles.visibleFlag : styles.hiddenFlag, // Apply visibility styles
        isSelected && styles.selectedFlag, // Highlight the selected flag
      ]}
    >
      <Flag code={code} size={32} />
      {/* Always render the arrow, but show rotation only for selected */}
      <Animated.View
        style={{
          transform: [
            {
              rotate: isSelected
                ? arrowRotation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '180deg'], // Rotate arrow
                  })
                : '0deg',
            },
          ],
          opacity: isSelected ? 1 : 0, // Show the arrow only for the selected flag
        }}
      >
        <MaterialIcons name="keyboard-arrow-down" size={24} color="white" />
      </Animated.View>
    </TouchableOpacity>
  );

  // Reorder flags so the selected flag is rendered first
  const reorderedFlags = [
    flags.find((flag) => flag.lang === selectedFlag), // Selected flag first
    ...flags.filter((flag) => flag.lang !== selectedFlag), // Other flags
  ];

  return (
    <View style={styles.languageSelector}>
      {reorderedFlags.map(({ code, lang }) =>
        renderFlag(code, lang, lang === selectedFlag, expanded || lang === selectedFlag)
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  languageSelector: {
    position: 'absolute',
    right: 10, // Align to the right
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: 1, // Ensure this view is above other components
  },
  languageButton: {
    marginVertical: 5, // Space between flags
    flexDirection: 'row', // Display flag and arrow in a row
    alignItems: 'center',
    width: 40, // Ensure consistent width for alignment
    transition: 'opacity 0.3s ease, transform 0.3s ease', // Smooth transition for visibility and position
  },
  visibleFlag: {
    opacity: 1, // Fully visible
    transform: [{ translateY: 0 }], // Reset position
  },
  hiddenFlag: {
    opacity: 0, // Fully hidden
    transform: [{ translateY: -20 }], // Move flags upwards when hidden
    pointerEvents: 'none', // Disable interaction when hidden
  },
  selectedFlag: {
    opacity: 1, // Always visible
    pointerEvents: 'auto', // Allow interaction
  },
});

export default LanguageSelector;