import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAr3lg3wSz5wDfkyPQJMToOctj3LlHgZvU",
  authDomain: "pepai-99e26.firebaseapp.com",
  projectId: "pepai-99e26",
  appId: "1:862765294469:ios:1f463f9693b2950fb75aec",
};

const firebaseWebConfig = {
  apiKey: "AIzaSyDH2hfohvLG5ikCBqc5A2FwzC-iIpHSq88",
  authDomain: "pepai-99e26.firebaseapp.com",
  databaseURL: "https://pepai-99e26-default-rtdb.firebaseio.com",
  projectId: "pepai-99e26",
  storageBucket: "pepai-99e26.appspot.com",
  messagingSenderId: "862765294469",
  appId: "1:862765294469:web:3e6dbabd39e61da0b75aec",
  measurementId: "G-PEZZ90HBH6"
}

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const webApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { db , webApp , analytics};
