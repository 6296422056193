import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Background from '../views/Bg';
import Footer from '../views/Footer';
import { fetchAndCacheTexts, getCachedTexts } from '../utils/FetchTexts';

const TermsOfUse = () => {
  const [texts, setTexts] = useState(null);
  const navigation = useNavigation(); // React Navigation for web compatibility

  useEffect(() => {
    const initializeTexts = async () => {
      const fetchedTexts = await getCachedTexts();
      if (fetchedTexts) {
        setTexts(fetchedTexts);
        console.log('Texts loaded successfully:', fetchedTexts);
      }
    };

    initializeTexts();
  }, []);

  if (!texts) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#fff" />
      </View>
    );
  }

  const { title, sections } = texts.TermsOfUse;

  return (
    <View style={styles.container}>
      <Background />
      <ScrollView contentContainerStyle={styles.content}>
        <View style={styles.header}>
          {/* Back button for web */}
          <TouchableOpacity onPress={() => navigation.navigate('Home')} style={styles.backButton}>
            <Text style={styles.backButtonText}>←</Text>
          </TouchableOpacity>
          <Text style={styles.title}>{title}</Text>
        </View>
        {sections.map((section, index) => (
          <View key={index}>
            <Text style={styles.sectionTitle}>{section.sectionTitle}</Text>
            <Text style={styles.paragraph}>{section.content}</Text>
          </View>
          
        ))}
        <Footer 
        navigation={navigation} 
        privacypolicy={texts.Footer?.privacypolicy} 
        termsofuse={texts.Footer?.termsofuse} 
        style={styles.footer} 
      />
      </ScrollView>
      
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141218',
  },
  errorText: {
    color: 'red',
    fontSize: 18,
    textAlign: 'center',
  },
  container: {
    flex: 1, // Ensure container takes full height
    backgroundColor: '#141218',
  },
  content: {
    flexGrow: 1, // Ensure ScrollView content expands fully
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  header: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Centers title horizontally
    marginBottom: 15,
  },
  backButton: {
    position: 'absolute',
    left: 0, // Aligns the back button to the left
    padding: 10,
  },
  backButtonText: {
    fontSize: 18,
    color: '#fff',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#ffffff',
    textAlign: 'center', // Ensures the title text is centered
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#ffffff',
    marginVertical: 10,
  },
  paragraph: {
    fontSize: 16,
    color: '#cccccc',
    lineHeight: 24,
    marginBottom: 10,
  },
  footer: {
    marginTop: 0, // Ensure no gap between content and footer
  },
});

export default TermsOfUse;